import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/login-signup/services/auth.service';
import { Roles } from 'src/app/utils/user.model';



@Injectable({
  providedIn: 'root'
})

export class EditorGurad {

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {

  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | Observable<boolean> | undefined> {
    if (await this.authService.UserHasRole([Roles.EDITOR]) === false) return true;

    this.router.navigate(['/quiz-land/quiz-tank']);
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | Observable<boolean> | undefined> {
    return this.canActivate(route, state);
  }
}
